import * as React from "react";
import styled from "styled-components"
import { Palette, Icons, Typography } from "../utils/Theme"
interface IconView {
	icon: Icons
	size?: number
	width?: number
	height?: number
	color?: string
	onClick?: () => void | undefined
	id?: string
}

export const IconView = React.memo((props: IconView) => {
	const iconSize = props.size ? props.size : 14
	const color = props.color ? props.color : Palette.grey

	const Icon = styled(Typography.IconFont)`
    font-size: ${iconSize}px;
    width: ${ props.width ? props.width : iconSize}px;
    height:${ props.height ? props.height : iconSize}px; 
    color: ${color};
    text-align: center;
    align-self: center;
    cursor: ${(props: { isClickable: boolean }) => props.isClickable ? "pointer" : "default"};
`
	return <Icon id={props.id} dangerouslySetInnerHTML={{ __html: props.icon }} isClickable={typeof props.onClick === "undefined" ? false : true} onClick={props.onClick} ></Icon>
})
