import * as React from "react";
import { useContext } from "react";
import styled from "styled-components"
import { ContentContainer } from "./ContentContainer";
import { Typography, Palette } from "../utils/Theme"
import { TreatmentLocations, IsLoadingContext } from "../pages/main"
import Lottie from 'lottie-react-web'
import { GridGroup } from "./GridGroup"

const loadingAnimation = require("../static/loading3.json")
const welcomeText = "Velkommen til ventetider.no"

// Her you can configure grouping of distances displayed in the search result
const distances = [
    {
        heading: "",
        min: 0,
        max: 20
    },
    {
        heading: "Mer enn 20 km unna",
        min: 20.001,
        max: 50
    },
    {
        heading: "Mer enn 50 km unna",
        min: 50.001,
        max: 10000
    },
]

export const TreatmentsList = React.memo((props: { sortByDistance: boolean, data?: TreatmentLocations, hasError?: boolean, lat?: number, long?: number, isIE?: boolean }) => {
    const isLoading = useContext(IsLoadingContext)
    const arrayOfLocations = props.data && props.data["WaitTimeData"]
    const latLongFromArray = props.data && props.data["SearchAsLatLong"]
    const userLat = props.lat ? props.lat : latLongFromArray && parseFloat(latLongFromArray.toString().split(",")[0])
    const userLong = props.long ? props.long : latLongFromArray && parseFloat(latLongFromArray.toString().split(",")[1])

    // Conditional styles for IE browser
    const BrowserSpesificLottieContainer = props.isIE ? IEStyledLottieContaier : StyledLottieContaier
    const BrowserSpesificContentContainer = props.isIE ? IEContentContaier : ContentContainer

    return (
        <>
            {props.hasError && <ErrorContainer><p>Klarte ikke å laste inn resultater, prøv igjen</p></ErrorContainer>}
            {isLoading &&
                <BrowserSpesificLottieContainer>
                    <Lottie
                        height={400}
                        width={400}
                        options={{
                            animationData: loadingAnimation,
                            looping: true,
                        }}
                    />
                </BrowserSpesificLottieContainer>
            }
            {props.data && !isLoading &&
                <BrowserSpesificContentContainer>
                    <StackContainer>
                        {props.sortByDistance ?
                            distances.map((item, i) => {
                                return (
                                    <GridGroup
                                        data={arrayOfLocations && arrayOfLocations}
                                        minDistance={item.min}
                                        maxDistance={item.max}
                                        heading={item.heading}
                                        userLat={userLat && userLat}
                                        userLong={userLong && userLong}
                                        isIE={props.isIE}
                                        sortByDistance={props.sortByDistance}
                                    />
                                )

                            })
                            :
                            <GridGroup
                                data={arrayOfLocations && arrayOfLocations}
                                minDistance={0}
                                maxDistance={100000}
                                userLat={userLat && userLat}
                                userLong={userLong && userLong}
                                isIE={props.isIE}
                                sortByDistance={props.sortByDistance}
                            />
                        }
                    </StackContainer>
                </BrowserSpesificContentContainer>
            }
            {props.data === undefined && !isLoading &&
                <FlexContainer>
                    <LargeText>{welcomeText}</LargeText>
                </FlexContainer>
            }
        </>
    )
})

const FlexContainer = styled.div`
    height: 70vh;
    width: 100%;
    display: flex;
    position: static;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    
    > p {
        max-width: 70%;
        text-align: center;
        color: ${Palette.Text.grey};
    }
`

const LargeText = styled(Typography.Header)`
`

const IEContentContaier = styled(ContentContainer)`
    display: block;
    transform: translateX(-50%);
    max-width: 70%;
`

const StyledLottieContaier = styled(FlexContainer)``

const IEStyledLottieContaier = styled(FlexContainer)`
    display: table;
    width: 100%;
    height: 100%;
    transform: translateY(20%);
`

const ErrorContainer = styled.div`
    height: 50%;
    width: 50%;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    opacity: 0.3;
    > p {
        color: white;
        font-size: 20pt;
    }
`

const StackContainer = styled.div`
    width: 100%;
    > div {
        margin: 10px 0 10px 0;
    }
`


