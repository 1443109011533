
// @ts-nocheck

import * as React from "react";
import { useState, useEffect } from "react"
import MainPage from "./main"
import { Palette } from "../utils/Theme";
import { device } from "../utils/Breakpoints";
export const window = require("global/window")
export type IsIE = boolean | undefined


export default function Home() {
	const [isIE, setIsIE] = useState<IsIE>(undefined)
	// window.navigator.userAgent.indexOf("Edge") > -1
	useEffect(() => {
		if ("ActiveXObject" in window) {
			setIsIE(true);
		} else {
			setIsIE(false);
		}

	}, [])
	return (
		<div>
			{isIE === true &&
				<div>
					<MainPage isIE={true}></MainPage>
				</div>
			}
			{isIE === false &&
				<div>
					<MainPage isIE={false}></MainPage>
				</div>
			}
		</div>
	)
}



