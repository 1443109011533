/* eslint-disable no-sequences */
import { useLayoutEffect, useEffect, useState } from 'react';
import { CategoryKey } from "../components/GridGroup"

export function FormatWeekName(weekCount: number) {
	if (weekCount > 1) {
		return "Uker"
	} else {
		return "Uke"
	}
}

export function IsUnilabsLocation(name: string) {
	if (!name.search("Unilabs")) {
		return true
	} else return false
}

export function UseLockBodyScroll() {
	useLayoutEffect((): any => {
		const originalOverflow = window.getComputedStyle(document.body).overflow;
		const originalPosition = window.getComputedStyle(document.body).position;
		document.body.style.overflow = "hidden";
		document.body.style.position = "fixed";
		return () => {
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			document.body.style.overflow = originalOverflow,
				document.body.style.position = originalPosition
		}
	}, []);
}

export function CalcLatLongDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
	var R = 6371; // Radius of the earth in km
	var dLat = deg2rad(lat2 - lat1);  // deg2rad below
	var dLon = deg2rad(lon2 - lon1);
	var a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
		Math.sin(dLon / 2) * Math.sin(dLon / 2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	var d = R * c; // Distance in km
	return d;
}

function deg2rad(deg: number) {
	return deg * (Math.PI / 180)
}

export function IsIEBrowser() {
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");

	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
	{
		return true
	}
	else  // If another browser, return 0
	{
		return false
	}
}

// TODO: Solve this a better way, by not using singleton
export let UserLocation = "";

export function useObserveString(input: string) {
	const [inputValue, setInputValue] = useState("")
	useEffect(() => {
		if (input) setInputValue(input);
	})
	UserLocation = inputValue
}

export function OpenGoogleMaps(lat: number, long: number) {
	let destination = ""
	let destinationToString = destination.concat(lat.toString(), ",", long.toString())
	let userLocation = UserLocation
	if (('standalone' in window.navigator)) {
		let win = window.open(`https://www.google.com/maps/dir/?api=1&origin=${userLocation}&destination=${destinationToString}`, "_top")
		win && win.focus()
	} else {
		let win = window.open(`https://www.google.com/maps/dir/?api=1&origin=${userLocation}&destination=${destinationToString}`, "_blank")
		win && win.focus()
	}
}

export function OpenOrderForm() {
	let win = window.open("https://timebestilling.unilabs.no/Timebestilling.aspx?_ga=2.214733335.1210783906.1570454859-1811930239.1555411926", "_blank")
	win && win.focus()
}



export function FormatCategoryName(category: CategoryKey) {
	switch (category) {
		case CategoryKey.MR:
			return "MR"
		case CategoryKey.CT:
			return "CT"
		case CategoryKey.Annet:
			return "Røntgen/Ultralyd/Annet"
		default:
			return "No matching Category"
	}
}
