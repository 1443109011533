import * as React from "react"
import styled from "styled-components"
import { Typography, Palette, Spacing, BorderRadius, DefaultFontFamily, Icons } from "../utils/Theme"
import { device } from "../utils/Breakpoints";
import { IconView } from "./IconView"

export function InfoRow(props: { text?: string, icon?: Icons, hasUnderline?: string }) {
    const isEmailAddress = props.text && props.text.includes("@") ? true : false

    return (
        props.text ?
            <Container>
                <AlignHorizontal>
                    <div className="iconWrapper">
                        {props.icon &&
                            <IconViewContainer icon={props.icon} size={20}></IconViewContainer>
                        }
                    </div>
                    <MediumTextShrinkOnMobile>{props.text}</MediumTextShrinkOnMobile>
                </AlignHorizontal>
                {typeof props.hasUnderline !== "undefined" &&
                    <HorizontalSeperatorLine />
                }
            </Container>
            :
            null
    )
}

const Container = styled.div`
    flex-direction: column;
    min-width: 100%;
    display: flex;
    flex: 0;
    @media ${device.mobileL} {
        margin: 0;
    }
`

const AlignHorizontal = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    width: 100%;
    min-width: 100%;
    @media ${device.mobileL} {
        margin: 0;
    }
    .iconWrapper {
        max-height: 10px;
        display: flex;
    }
`

const IconViewContainer = styled(IconView)`
    display: flex;
    max-height: 10px;
    height: 10px;
`

const EmailLink = styled.a`
    font-size: 1.1rem;
    font-family: ${DefaultFontFamily};
    color: ${Palette.Text.slate};
    margin: 0;
    margin-left: ${Spacing.small};
    margin-right: ${Spacing.small};
    @media ${device.mobileL} {
        font-size: 0.85rem;
    }
`

const MediumTextShrinkOnMobile = styled(Typography.MediumBody)`
    margin-left: ${Spacing.small};
    margin-right: ${Spacing.small};
    
    @media ${device.mobileL} {
        font-size: 0.85rem;           
    }
`

const HorizontalSeperatorLine = styled.div`
	height: 1px;
	width: 100%;
	min-width: 100%;
	background-color: rgba(0,0,0,0.08);
	margin: ${Spacing.medium} 0;
	z-index: 10;
	@media ${device.mobileL} {
		margin: ${Spacing.small} 0;
	}
`