import * as React from "react";
import styled from "styled-components"
import { ContentContainer } from "../components/ContentContainer"
import { Palette, Typography, Spacing, Icons } from "../utils/Theme"
import { Button } from "../components/Button"
import { Link } from "react-router-dom";


export default function AboutPage() {
    return (
        <StyledContentContainer>
            <HeaderText>Kilde for ventetider er Helsenorge.no.</HeaderText>
            <MediumText>Ventetider.no er en tjeneste levert av Unilabs Norge AS</MediumText>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <Button label={"TILBAKE TIL HOVEDSIDEN"} onClick={() => { }} maxWidth={250}></Button>
            </Link>
        </StyledContentContainer>
    )
}


export const StyledContentContainer = styled(ContentContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 100vh;
`

export const HeaderText = styled(Typography.Header)`
    margin-bottom: ${Spacing.medium} 0;
    text-align: center;
    align-self: center;
    color: ${Palette.primaryColor};
    font-size: 2.5em;
`

export const MediumText = styled(Typography.LargeBody)`
align-self: center;
    margin: ${Spacing.large} 0;
    text-align: center;
    color: ${Palette.grey};
`
