import styled from "styled-components"
import { device } from "./Breakpoints"

export enum Icons {
  gps = "&#xe905;",
  pin = "&#xe906;",
  search = "&#xe907;",
  close = "&#xe908;",
  questionBubble = "&#xe904;",
  bekken = "&#xe903;",
  bukhulen = "&#xe902;",
  hodet = "&#xe900;",
  nakke = "&#xe901;",
  info = "&#xe909;",
  parking = "&#xe90a;",
  contact = "&#xe90b;",
  thorax = "&#xe90c;",
  kidney = "&#xe90d;",
  bones = "&#xe90e;",
  logo = "&#xe90f;",
  categoryFallbackIcon = "&#xe910;",
  downArrow = "&#xe911;"
}

// PALETTE
export const Palette = {
  primaryColor: "#27227A",
  secondaryColor: "#F09600",
  faintBackground: "#F2F6F8",
  lightGrey: "#AAAAAA",
  grey: "#7A8395",
  Text: {
    slate: "#343434",
    grey: "#6F6F6F"
  },
  blue: "#2196F3"
}

//TYPOGRAPHY
export const DefaultFontFamily = "'Roboto', sans-serif;"
export const IconFont = "Icons"
export const Typography = {
  IconFont: styled.p`
    font-family: "Icons";
  `,
  Header: styled.p`
    font-size: 1.5em;
    font-family: ${DefaultFontFamily};
    color: ${Palette.Text.slate};
    margin: 0;
  `,
  LargeBody: styled.p`
    font-size: 1.2rem;
    font-family: ${DefaultFontFamily};
    color: ${Palette.Text.slate};
    margin: 0;
    @media ${device.mobileL} {
      font-size: 1.1rem;
    }
  `,
  MediumBody: styled.p`
    font-size: 1.1rem;
    font-family: ${DefaultFontFamily};
    color: ${Palette.Text.slate};
    margin: 0;
    @media ${device.mobileL} {
      font-size: 1rem;
    }
  `,
  SmallBody: styled.p`
    font-size: 0.9rem;
    font-family: ${DefaultFontFamily};
    color: ${Palette.Text.slate};
    margin: 0;
  `
}

export const Spacing = {
  large: 32 + "px",
  medium: 16 + "px",
  small: 8 + "px",
  verySmall: 4 + "px"
}

export const BorderRadius = 4
