// @ts-nocheck

import React from "react";
import "../CSS/styles.css";
import "../CSS/modal.css";
import { Button } from "./Button";
import styled, { keyframes } from "styled-components"
import FlexLine2 from "./FlexLine2"
import { Typography, Palette, Spacing, BorderRadius, Icons } from "../utils/Theme"
import { ContentContainer } from "./ContentContainer"
import { InfoRow } from "./InfoRow";
import { IconView } from "./IconView"
import { device } from "../utils/Breakpoints"
import { FormatWeekName, IsUnilabsLocation, UseLockBodyScroll, OpenGoogleMaps, OpenOrderForm, FormatCategoryName } from "../utils/HelperFunctions"

export type TreatmentOptions = {
    "Bekken": number,
    "Bukhulen (abdomen)": number,
    "Ekstremiteter (armen/ben)": number,
    "Nakke/Rygg (columna)": number,
    "Hodet (caput)": number
}

export function Modal2(props: {
    isVisible,
    title,
    content,
    onClose,
    name,
    weekCount,
    treatmentOptions,
    treatmentCategory

}) {

    React.useEffect(() => {
        document.addEventListener("keydown", keydownHandler);
        return () => document.removeEventListener("keydown", keydownHandler);
    });

    function keydownHandler({ key }) {
        switch (key) {
            case "Escape":
                onClose();
                break;
            default:
        }
    }
    function setIcon(category: string) {
        switch (category) {
            case "Bekken (pelvis)":
                return Icons.bekken
            case "Bukhulen (abdomen)":
                return Icons.bukhulen
            case "Armer/ben (ekstremiteter)":
                return Icons.bones
            case "Nakke/Rygg (columna)":
                return Icons.nakke
            case "Nyrer/ Urinveier":
                return Icons.kidney
            case "Brysthulen (thorax)":
                return Icons.thorax
            case "Hodet (caput)":
                return Icons.hodet
            default:
                return Icons.categoryFallbackIcon
        }
    }

    /*
    const sortedTreatmentOptions: { [key: string]: number } = Object.entries()
        .sort(([, a], [, b]) => +a - +b)
        .reduce((r, [key, val]) => Object.assign(r, { [key]: val }), {});
*/
    const isIE = true;
    const isVisible = true;

    function entriesPolyFill(obj) {
        const keys = Object.keys(obj);

        const keyValuePairs = keys.map(key => {
            const value = obj[key];

            return [key, value];
        });

        return keyValuePairs;
    };



    return !props.isVisible ? null : (
        <div className="modal" onClick={props.onClose}>
            <div className="modal-dialog" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h3 className="modal-title">{props.name}</h3>
                    <span className="modal-close" onClick={props.onClose}>
                        &times;
          </span>
                </div>
                <div className="modal-body">
                    <div className="modal-content">{
                        <div>
                            <StackContainer>
                                <IETreatmentListHeader>
                                    <TreatmentListHeaderText>{IsUnilabsLocation(props.name) ? "Velg behandling" : "Behandling"}</TreatmentListHeaderText>
                                </IETreatmentListHeader>
                                <div>
                                    {entriesPolyFill(props.treatmentOptions).map((keyVal, i) => (
                                        <TreatmentOption id="treatmentItem" key={i} name={keyVal[0]} weekcount={keyVal[1]} onClick={() => { }} icon={setIcon(keyVal[0])} bookingAvailable={IsUnilabsLocation(props.name)} isIE={isIE} />
                                    ))}
                                </div>
                            </StackContainer>
                        </div>
                    }</div>
                </div>
                {<div className="modal-footer">{"footer"}</div>}
            </div>
        </div>
    );
}

function TreatmentOption(props: { id: string, name: string, weekcount: number, onClick: () => void, icon?: Icons, bookingAvailable: boolean, isIE?: boolean }) {
    const BrowserSpesificContainer = props.isIE ? Treatment.IEContainer : Treatment.Container

    return (
        <BrowserSpesificContainer id={props.id} isUnilabsLocation={props.bookingAvailable}>
            <div className="cell1">
                <Treatment.RoundIconContainer>
                    {props.icon &&
                        <IconView icon={props.icon} size={20} ></IconView>
                    }
                </Treatment.RoundIconContainer>
            </div>
            <div>
                <MediumText className="label">{props.name}</MediumText>
            </div>

            <div className="cell2">
                <MediumText>{props.weekcount}</MediumText>
                <MediumText className="weekCountLabel">{FormatWeekName(props.weekcount)}</MediumText>
            </div>
            {props.bookingAvailable &&
                <div className="cell3">
                    <Button maxWidth={70} className="orderButton" label={"bestill"} onClick={OpenOrderForm} ></Button>
                </div>
            }
        </BrowserSpesificContainer>
    )
}

export const ListItemsColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
`


export const ButtonContainer2 = styled.div`
background-color: white;
height: 45px;
min-width: 100px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 4px;
max-width: 100px;
`
export const Label2 = styled(Typography.MediumBody)`
color:white;
`
const ModalHeader = styled(Typography.LargeBody)`
    color: ${Palette.primaryColor};
`

const MediumText = styled(Typography.MediumBody)`
margin-right:2rem;
`

export const MediumText2 = styled(Typography.MediumBody)`
    color: black;
`
export const MediumText3 = styled(Typography.MediumBody)`
    color: black;
    font-weight:800;
`

export const LargeText = styled(Typography.LargeBody)`
    color: ${Palette.primaryColor};
    font-size: 2rem;
`

export const ContentWrapper = styled(ContentContainer)`
    display: flex;
	background-color:white;
    overflow: hidden;
`


export const StackContainer = styled.div`
	display: flex;
    flex-direction: column;
    align-items:center;
    width: 100%;
`
export const ListItemContainer = styled.div`
	display: flex;
    justify-content: space-between;
    width: 90%;
`
export const ListItemsWrapper = styled.div`
	display: flex;
    align-items:center;
    width: 90%;
    margin: 1rem;
    
`
//    grid-template-columns: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};

export const ListHeaderContainer = styled.div`
    align-items:center;
    width:720px;
    display: -ms-inline-grid;
    display:grid;
    height: 50px;
    padding:${Spacing.medium};
    border-bottom: 1px solid rgba(0,0,0,0.08);
    -ms-grid-columns: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};
    grid-template-columns: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};
    
`

const ButtonContainer = styled.div`
    background-color: ${Palette.primaryColor};
    height: 45px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0.5rem;
    padding: 0 ${Spacing.medium};
    -webkit-transition: background-color 0.2s; /* Safari prior 6.1 */
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
        transition: background-color 0.3s ease-in-out;
        background-color: #1D1A5F;
        -webkit-transition: background-color 0.2s; /* Safari prior 6.1 */
        transition: background-color 0.3s ease-in-out;
    }
    
`

const Label = styled(Typography.MediumBody)`
text-transform: uppercase;
color: white;
font-size: 0.9rem;
cursor: pointer;
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
-khtml-user-select: none; /* Konqueror HTML */
-moz-user-select: none; /* Old versions of Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

`

const Treatment = {
    Container: styled.div`        
        display: grid;
        padding:${Spacing.medium};
        border-bottom: 1px solid rgba(0,0,0,0.08);
        grid-template-columns: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .cell1 {
        }
        .cell2 {
            justify-content: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "center" : "flex-end"};
            .weekCountLabel {
                margin-left: ${Spacing.verySmall};
            }
        }
        .cell3 {
            justify-content: flex-end;
        }
    `,
    IEContainer: styled.div`        
    display: grid;
    height: 50px;
    padding:${Spacing.medium};
    border-bottom: 1px solid rgba(0,0,0,0.08);
    grid-template-columns: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};
    background-color: pink;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }
    .cell1 {
    }
    .cell2 {
        justify-content: "flex-end";
        margin-right: 200px;
        transform: translateY(-150%);
        .weekCountLabel {
            margin-left: ${Spacing.verySmall};
        }
    }
    .cell3 {
        justify-content: flex-end;
        transform: translateY(-150%);
    }
    `,
    RoundIconContainer: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        min-height: 50px;
        min-width: 50px;
        border-radius: 25px;
        background-color: ${Palette.faintBackground};
        margin-right: ${Spacing.small};
        margin-left: 1rem;
        @media ${device.mobileL} {
            display: none;           
        }
    `
}

const TopContaner = styled.div`
        display: table;
        overflow: auto;
        min-height: 40px;
        padding: ${Spacing.medium} ${Spacing.medium} 0 ${Spacing.medium};
        justify-content: stretch;
        @media ${device.mobileL} {
            padding-bottom: ${Spacing.small};
        }
    `
const BottomContainer = styled.div`
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        margin-top: 0;
        max-height: 100%;
    `
const TreatmentContainer = styled.div`
        
       
    `

const Heading = styled(Typography.Header)`
        color:${Palette.primaryColor};
        @media ${device.mobileL} {
             font-size: 1.3rem;
             padding-bottom: ${Spacing.small};
             padding-right: ${Spacing.small};
        }
    `
const StackVertical = styled.div`
        flex-direction: column;
        width: 100%;
        .seperatorContainer {
            display: flex;
            align-self: center;
            justify-content: center;
            width: 100%;
        }
    `
const StackHorizontal = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 100%;
        width: 100%;
    `
const AddressContainer = styled.div`
        flex-direction: row;
        display: flex;
        align-items: center;
        margin: 0;
        margin-top: ${Spacing.verySmall};
        height: 30px;
        margin-bottom: ${Spacing.medium};
        .secondRow {
            align-items: center;
            display: flex;
            flex-direction: row;
            max-height: 25px;
        }
        .noSpace {
            margin: 0 ${Spacing.verySmall};
        }
        @media ${device.mobileL} {
            margin-top: 0;
            margin-bottom: 0;
        }
    `

const PinIcon = styled(IconView)`
        @media ${device.mobileL} {
                display: none;
            }
    `
const DirectionsButton = styled.div`
        display: flex;
        flex-direction: row;
        background-color: ${Palette.faintBackground};
        align-items: center;
        justify-content: center;
        padding: 0 ${Spacing.small};
        height: 34px;
        border-radius: 15px;
        cursor: pointer; 
        @media ${device.mobileL} {
            min-height: 34px;
            min-width: 34px;
            max-height: 34px;
            max-width: 34px;
        }
    `
const CategoryLabelContaine = styled.div`
        display: flex;
        flex-direction: row;
        background-color: ${Palette.faintBackground};
        align-items: center;
        justify-content: center;
        padding: 0 ${Spacing.small};
        height: 34px;
        border-radius: 15px;
        margin-left: ${Spacing.small};
    `
const SmallLabel = styled(Typography.SmallBody)`
        color: ${(props: { color?: string, fontWeight?: number }) => props.color ? props.color : Palette.Text.slate};
        font-weight: ${(props: { color?: string, fontWeight?: number }) => props.fontWeight ? props.fontWeight : 400};
        margin-left: ${Spacing.verySmall};
        align-self: center;
        @media ${device.mobileL} {
            display: none;
        }
    `

const HorizontalSeperatorLine = styled.div`
        height: 1px;
        width: 100%;
        margin: ${Spacing.small} 0;
        background-color: rgba(0,0,0,0.08);
    `

const TreatmentListHeader = styled.div`
        height: 40px;
        max-height: 40px;
        min-height: 40px;
        flex: 1;
        background-color: ${Palette.faintBackground};
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        padding-left: ${Spacing.small};
        @media ${device.mobileL} {
        
        }
    `
const IETreatmentListHeader = styled.div`
        height: 40px;
        padding-top: 16px;
        background-color: ${Palette.faintBackground};
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
        display: table;
        width: 730px;
        align-items: center;
        padding-left: ${Spacing.medium};
        
    `
const TreatmentListHeaderText = styled(Typography.MediumBody)`
    `


const translation = keyframes`
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    
  `;