import * as React from "react";
import styled from "styled-components"
import { ContentContainer } from "./ContentContainer";
import { DefaultFontFamily, Palette, Spacing, Icons } from "../utils/Theme"
import { device } from "../utils/Breakpoints";
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useState, useEffect } from "react";
import { IsIE } from "../pages/index"
import { IconView } from "./IconView";
import { Link } from "react-router-dom";

type ScrollPosition = {
  prevPos: { [key: string]: number }
  currPos: { [key: string]: number }
}

export const NavBar = (props: { searchBar?: React.ReactNode, dontGetSearchbarPos?: boolean }) => {
  const [backgroundOnScroll, setBackgroundOnScroll] = useState(false);
  const [searchOnScroll, setSearchOnScrollOnScroll] = useState(false);
  const [isIE, setIsIE] = useState<IsIE>(true)

  let searchBarPos = 0

  const transformScriptTags = () => setOffsetTop();
  if (typeof window !== 'undefined' && window && window.addEventListener) {
    window.addEventListener('DOMContentLoaded', transformScriptTags, false);
  }

  function setOffsetTop() {
    searchBarPos = document.getElementById("searchBar")!.offsetTop;
  }

  useScrollPosition(({ prevPos, currPos }: ScrollPosition) => {
    const showBackground = currPos.y < -16
    const showSearchBar = searchBarPos !== null ? currPos.y < -searchBarPos : null
    if (showSearchBar !== searchOnScroll) setSearchOnScrollOnScroll(showSearchBar!)
    if (showBackground !== backgroundOnScroll) setBackgroundOnScroll(showBackground)
  }, [backgroundOnScroll, searchOnScroll])

  useEffect(() => {
    if ("ActiveXObject" in window) {
      setIsIE(true);
    } else {
      setIsIE(false);
    }
  })

  return (
    !isIE ?
      <NavBarContainer id="navBar" show={backgroundOnScroll}>
        <ContentContainer>
          <NavBarContentContainer>
            <div className="left">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <LogoIcon icon={Icons.logo} color={Palette.primaryColor} size={19} width={150} ></LogoIcon>
              </Link>
              <SearchBarContainer show={backgroundOnScroll}>
                {props.searchBar}
              </SearchBarContainer>
            </div>
            <div className="pointCursor" >
              <Link to="/about" style={{ textDecoration: 'none' }}>
                <ThemeButton><p>OM</p></ThemeButton>
              </Link>
            </div>
          </NavBarContentContainer>
        </ContentContainer>
      </NavBarContainer >
      :
      <IENavBarContainer>
        <IEinnerWrapper>
          <IEDockPanel dockRight={false}>
            <div className="left">
              <Link style={{ textDecoration: 'none' }} to="/">
                <LogoIcon icon={Icons.logo} color={Palette.primaryColor} size={19} width={150} ></LogoIcon>
              </Link>
            </div>
          </IEDockPanel>
          <IEDockPanel dockRight={true}>
            <div className="pointCursor">
              <ThemeButton><Link style={{ textDecoration: 'none', color: "#27227A" }} to="/about"><p>OM</p></Link></ThemeButton>
            </div>
          </IEDockPanel>
        </IEinnerWrapper>
      </IENavBarContainer>
  )
};


const IENavBarContainer = styled.div`
  display: static;
  height: 60px;
  position: fixed; 
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 6px;
  background-color: white;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`

const IEinnerWrapper = styled(ContentContainer)`
  position: absolute;
  display: flex;
  justify-content: space-between;
`
const IEDockPanel = styled.div`
  right: ${(props: { dockRight: boolean }) => props.dockRight ? "0" : "undefined"};
  left: ${(props: { dockRight: boolean }) => props.dockRight ? "undefined" : "0"};
  .pointCursor {
    cursor: pointer;
    text-decoration: none;
  }
`


const NavBarContainer = styled.div`
  height: 60px;
  width: 100vw;
  display: flex;
  position: fixed;
  z-index: 10;
  border-bottom: ${(props: { show: boolean }) => props.show ? "1px solid rgba(0,0,0,0.08)" : "1px solid rgba(0,0,0,0.0)"} ;
  -webkit-box-shadow: ${(props: { show: boolean }) => props.show ? "0px 3px 3px -2px rgba(0,0,0,0.05)" : "0px 3px 3px -2px rgba(0,0,0,0.0)"};
  -moz-box-shadow: ${(props: { show: boolean }) => props.show ? "0px 3px 3px -2px rgba(0,0,0,0.05)" : "0px 3px 3px -2px rgba(0,0,0,0.0)"};
  box-shadow: ${(props: { show: boolean }) => props.show ? "0px 3px 3px -2px rgba(0,0,0,0.05)" : "0px 3px 3px -2px rgba(0,0,0,0.0)"};
  background-color: ${(props: { show: boolean }) => props.show ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,0)"};
  transition: all 50ms ${(props: { show: boolean }) => props.show ? "ease-out" : "ease-in"};
`

const NavBarContentContainer = styled(ContentContainer)`
  display: flex;
  margin: 0 ${Spacing.medium};  
  width: 100%;
  justify-content: space-between;
  > p {
    color: black;
    font-family: ${DefaultFontFamily};
    
  }
  @media ${device.laptopL} {
    padding: 0 ${Spacing.small};
  }
  @media ${device.mobileL} {  
    padding: 0 0;
    margin: 0 ${Spacing.small};  
  }
  

  .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-decoration: none;
  }
  .pointCursor {
    cursor: pointer;
    text-decoration: none;
  }
`

const LogoIcon = styled(IconView)`
  margin: 0;
  padding: 0;
  align-self: center;
  text-align: center;
`

const SearchBarContainer = styled.div`
  height: 100%;
  margin-left: ${Spacing.medium};
  justify-content: flex-start;
  display: flex;
  width: 400px;
  align-self: center;
  opacity: ${(props: { show: boolean }) => props.show ? "1" : "0"};
  transition: all 150ms ${(props: { show: boolean }) => props.show ? "ease-out" : "ease-in"};
  @media ${device.mobileL} {
        display: none;
    }
`
const ThemeButton = styled.div`
  height: 40px;
  border: 1px solid ${Palette.primaryColor};
  border-radius: 20px;
  width: 120px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  > p {
    color: ${Palette.primaryColor}; 
    font-family: ${DefaultFontFamily};
    font-size: 10pt;
  }
`
