
import * as React from "react";
import { useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { Typography, Palette, Spacing, BorderRadius, Icons } from "../utils/Theme"
import { IconView } from "./IconView";
import ReactModal from "react-modal";
import { useState } from "react";
import { FormatWeekName, IsUnilabsLocation, UseLockBodyScroll, OpenGoogleMaps, OpenOrderForm, FormatCategoryName } from "../utils/HelperFunctions"
import { TreatmentOptions } from "../pages/main"
import { Button } from "./Button"
import { device } from "../utils/Breakpoints"
import { IsIE } from "../pages/index"
import { InfoRow } from "./InfoRow";
import { CategoryKey } from "./GridGroup";
import { Modal } from "./Modal";
import { Modal2 } from "./Modal2";

export const CardHeight = "110px";
export interface Treatment {
    weekcount: number,
    name: string,
    address: string,
    lat: number,
    long: number,
    onPressDirection?: () => void
    onClick?: () => void
    isVisible?: boolean
    parkingInfo?: string
    locationInfo?: string
    contactInfo?: string
}

// Nessesary for React Modal to work w/ SSR, since the modal will not be attached to the dom 

if (typeof (window) !== "undefined") {
    ReactModal.setAppElement("body")
}



export const TreatmentView = React.memo((props: Treatment & { treatmentOptions: TreatmentOptions, treatmentCategory?: any, isIE?: boolean }) => {
    const [modalViewIsOpen, setModalViewIsOpen] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isModal, setModal] = useState(false);

    function toggleModal() {
        setModalViewIsOpen(!modalViewIsOpen)
    }

    useEffect(() => {
        if (!props.name) setIsValid(false);
        if (!props.treatmentCategory) setIsValid(false);
        if (!props.weekcount) setIsValid(false);
        if (!props.address) setIsValid(false);
    }, [])

    return (
        isValid ?
            <>
                {modalViewIsOpen &&
                    <ModalView
                        isVisible={modalViewIsOpen}
                        onClick={toggleModal}
                        name={props.name}
                        weekcount={props.weekcount}
                        address={props.address}
                        treatmentOptions={props.treatmentOptions}
                        parkingInfo={props.parkingInfo}
                        locationInfo={props.locationInfo}
                        contactInfo={props.contactInfo}
                        treatmentCategory={props.treatmentCategory}
                        lat={props.lat}
                        long={props.long}
                    />
                }
                {isModal &&
                    <Modal2
                        isVisible={true}
                        title="Modal Title"
                        content={<p>Add your content here</p>}
                        onClose={() => setModal(false)}
                        name={props.name}
                        weekCount={props.weekcount}
                        treatmentOptions={props.treatmentOptions}
                        treatmentCategory={props.treatmentCategory}
                    />
                }

                <CardView
                    onClick={toggleModal}
                    name={props.name}
                    weekcount={props.weekcount}
                    address={props.address}
                    isIE={props.isIE}
                    lat={props.lat}
                    long={props.long}

                />
            </>
            :
            null
    )
})

// The card view displayed within a `Category` grid row.
function CardView(props: Treatment & { isIE?: boolean }) {

    return (
        <Card.Container onClick={props.onClick} >
            <Card.TimeView>
                <Card.WeekCount>{props.weekcount}</Card.WeekCount>
                <Card.WeekLabel>{FormatWeekName(props.weekcount)}</Card.WeekLabel>
            </Card.TimeView>
            <Card.InfoView>
                <div className="top">
                    <MediumText>{props.name}</MediumText>
                    {IsUnilabsLocation(props.name) &&
                        <img src={require("../static/unilabLogo.png")}></img>
                    }
                </div>
                <div className="bottom">
                    <Card.AddressView>
                        <IconView icon={Icons.pin} size={13}></IconView>
                        <Card.AddressLabel>{props.address}</Card.AddressLabel>
                    </Card.AddressView>
                    <Card.GeoIconContainer>
                        <div className="geoIconContainer">
                            <IconView icon={Icons.gps} size={20} color={Palette.blue} onClick={() => OpenGoogleMaps(props.lat, props.long)}></IconView>
                        </div>
                    </Card.GeoIconContainer>
                </div>
            </Card.InfoView>
        </Card.Container>
    )
}

// The modal view, displayed when a user clicks on a card.
function ModalView(props: Treatment & { treatmentOptions: TreatmentOptions, treatmentCategory: CategoryKey }) {
    const [isIOSDevice, setIsIOSDevice] = useState(false);
    const [isIE, setIsIE] = useState<IsIE>(undefined)
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false

    const InfoRowContainer = document.getElementById("infoRowContainer") != null ? document.getElementById("infoRowContainer")!.clientHeight : 0
    const [clientHeight, setClientHeight] = useState(0)
    const [scrollHeight, setScrollHeight] = useState(0)
    const [isScrollable, setIsScrollable] = useState(false)
    const [scrollArrowVisible, setScrollArrowVisible] = useState(true)

    function entriesPolyFill(obj: any) {
        const keys = Object.keys(obj);

        const keyValuePairs = keys.map(key => {
            const value = obj[key];

            return [key, value];
        });

        return keyValuePairs;
    };

    const sortedTreatmentOptions: { [key: string]: number } = entriesPolyFill(props.treatmentOptions)
        .sort(([, a], [, b]) => +a - +b)
        .reduce((r, [key, val]) => Object.assign(r, { [key]: val }), {});

    if ("ActiveXObject" in window) {

    } else {
        // Hook disabling scroll on body when modal is rendered
        UseLockBodyScroll();
    }


    /*
     Current implementation checks if the scrollHeight > clientHeight, as well as checking if the inforows consume more that 100px height and treatmentOptions > 4. Because of a CSS issue the the scrollHeight of the TreatmentContainer dont get calculated correctly when there is more that 2 inforows.  
     * TODO: Refactor BottomContent CSS to grow/shrink properly based on children, so iSScrollable can be solved by simply checking scrollHeight > clientHeight. 
     */
    useEffect(() => {
        if (document.getElementById("treatmentContainer") != null) {
            setClientHeight(document.getElementById("treatmentContainer")!.offsetHeight)
            setScrollHeight(document.getElementById("treatmentContainer")!.scrollHeight)

            if (scrollHeight > clientHeight || (InfoRowContainer > 100 && entriesPolyFill(props.treatmentOptions).length > 4)) {
                setIsScrollable(true)
            }

            document.getElementById("treatmentContainer")!.addEventListener("scroll", () => {
                const scrollPos = document.getElementById("treatmentContainer")!.scrollTop
                if (scrollPos > 0) {
                    setScrollArrowVisible(false)
                } else if (scrollPos <= 0) {
                    setScrollArrowVisible(true)
                }
            })
        }
    })
    //
    useEffect(() => {
        if ("ActiveXObject" in window || window.navigator.userAgent.indexOf("Edge") > -1) {
            setIsIE(true);
        } else {
            setIsIE(false);
        }
    })

    useEffect(() => {
        if ((navigator.userAgent.match(/iPhone/)) || (navigator.userAgent.match(/iPod/))) setIsIOSDevice(true)
    }, [])

    function setIcon(category: string) {
        switch (category) {
            case "Bekken (pelvis)":
                return Icons.bekken
            case "Bukhulen (abdomen)":
                return Icons.bukhulen
            case "Armer/ben (ekstremiteter)":
                return Icons.bones
            case "Nakke/Rygg (columna)":
                return Icons.nakke
            case "Nyrer/ Urinveier":
                return Icons.kidney
            case "Brysthulen (thorax)":
                return Icons.thorax
            case "Hodet (caput)":
                return Icons.hodet
            default:
                return Icons.categoryFallbackIcon
        }
    }

    // Conditional for IE / not IE
    const BrowserSpesificStyledModal = isIE ? ModalStyle.IEStyledModal : ModalStyle.StyledModal
    const BrowserTextHeader = isIE ? ModalStyle.IETreatmentListHeader : ModalStyle.TreatmentListHeader
    const StackContainerOnIE = isIE ? ModalStyle.StackVertical : React.Fragment
    return (
        <ModalStyle.AlignModalContainer>
            <BrowserSpesificStyledModal
                id="modalContaier"
                className="reactModal"
                isOpen={props.isVisible}
                shouldCloseOnEsc={true}
                style={ModalStyle.reactModalStyle}
                shouldCloseOnOverlayClick={true}
                onRequestClose={props.onClick}
                isIOS={isIOSDevice}
            >
                <ModalStyle.CloseButtonContainer onClick={props.onClick}>
                    <IconView
                        icon={Icons.close}
                        size={17} color={Palette.lightGrey}
                    />
                </ModalStyle.CloseButtonContainer>
                <StackContainerOnIE>
                    <ModalStyle.TopContaner>
                        <ModalStyle.StackVertical>
                            <ModalStyle.StackHorizontal>
                                <ModalStyle.Heading>{props.name}</ModalStyle.Heading>
                                {props.treatmentCategory &&
                                    <ModalStyle.SmallLabel fontWeight={500} color={Palette.primaryColor}>{" - " + FormatCategoryName(props.treatmentCategory)}</ModalStyle.SmallLabel>
                                }
                            </ModalStyle.StackHorizontal>
                            <ModalStyle.AddressContainer>
                                <div className="secondRow">
                                    <div className="noSpace">
                                        <ModalStyle.PinIcon
                                            icon={Icons.pin}
                                            color={Palette.Text.grey}
                                        />
                                    </div>
                                    <div className="noSpace">
                                        <MediumTextShrinkOnMobile>{props.address}</MediumTextShrinkOnMobile>
                                    </div>
                                </div>
                                <ModalStyle.DirectionsButton onClick={() => OpenGoogleMaps(props.lat, props.long)} >
                                    <IconView
                                        icon={Icons.gps}
                                        color={Palette.blue}
                                        size={15}
                                    />
                                    <ModalStyle.SmallLabel>Se veibeskrivelse</ModalStyle.SmallLabel>
                                </ModalStyle.DirectionsButton>
                            </ModalStyle.AddressContainer>
                            <ModalStyle.HorizontalSeperatorLine />
                            <ModalStyle.TextContentContainer id="infoRowContainer">
                                <InfoRow text={props.locationInfo} icon={isIE ? undefined : Icons.info} hasUnderline={props.locationInfo}></InfoRow>
                                <InfoRow text={props.parkingInfo} icon={isIE ? undefined : Icons.parking} hasUnderline={props.contactInfo}></InfoRow>
                                <InfoRow text={props.contactInfo} icon={isIE ? undefined : Icons.contact} ></InfoRow>
                            </ModalStyle.TextContentContainer>
                        </ModalStyle.StackVertical>
                    </ModalStyle.TopContaner>
                    <ModalStyle.BottomContainer>
                        <BrowserTextHeader>
                            <ModalStyle.TreatmentListHeaderText>{IsUnilabsLocation(props.name) ? "Velg behandling" : "Behandling"}</ModalStyle.TreatmentListHeaderText>
                        </BrowserTextHeader>
                        {isScrollable && isMobileDevice &&
                            <ModalStyle.GradientOverlayContainer>
                                {scrollArrowVisible &&
                                    <AnimatedArrowContainer>
                                        <IconView icon={Icons.downArrow} color={Palette.primaryColor} size={10}></IconView>
                                    </AnimatedArrowContainer>
                                }
                            </ModalStyle.GradientOverlayContainer>

                        }
                        <ModalStyle.TreatmentContainer id="treatmentContainer" isScrollable={isScrollable} >
                            {entriesPolyFill(sortedTreatmentOptions).map((keyVal, i) => (
                                <TreatmentOption id="treatmentItem" key={i} name={keyVal[0]} weekcount={keyVal[1]} onClick={() => { }} icon={setIcon(keyVal[0])} bookingAvailable={IsUnilabsLocation(props.name)} isIE={isIE} />
                            ))}
                        </ModalStyle.TreatmentContainer>
                    </ModalStyle.BottomContainer>
                </StackContainerOnIE>
            </BrowserSpesificStyledModal>
        </ModalStyle.AlignModalContainer>
    )
}


// A spesific treatment, displayed as a row within the ModalView
function TreatmentOption(props: { id: string, name: string, weekcount: number, onClick: () => void, icon?: Icons, bookingAvailable: boolean, isIE?: boolean }) {
    const BrowserSpesificContainer = props.isIE ? Treatment.IEContainer : Treatment.Container

    return (
        <BrowserSpesificContainer id={props.id} isUnilabsLocation={props.bookingAvailable}>
            <div className="cell1">
                <Treatment.RoundIconContainer>
                    {props.icon &&
                        <IconView icon={props.icon} size={20} ></IconView>
                    }
                </Treatment.RoundIconContainer>
                <MediumText className="label">{props.name}</MediumText>
            </div>
            <div className="cell2">
                <MediumText>{props.weekcount}</MediumText>
                <MediumText className="weekCountLabel" >{FormatWeekName(props.weekcount)}</MediumText>
            </div>
            {props.bookingAvailable &&
                <div className="cell3">
                    <Button maxWidth={70} className="orderButton" label={"bestill"} onClick={OpenOrderForm} ></Button>
                </div>
            }
        </BrowserSpesificContainer>
    )
}

const MediumText = styled(Typography.MediumBody)``
const MediumTextShrinkOnMobile = styled(Typography.MediumBody)`
    @media ${device.mobileL} {
            font-size: 0.85rem;           
        }
`
const Card = {
    Container: styled.div`
        height: ${CardHeight};
        flex:1;
        background-color: rgba(255,255,255,1);
        -webkit-box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.1);
        box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.1);
        border-radius: ${BorderRadius};
        display: flex;
        -webkit-transition: background-color 0.2s; /* Safari prior 6.1 */
        transition: background-color 0.2s;
        cursor: pointer;
        border-radius: 6px;
        &:hover {
            transition: background-color transform 0.3s ease-in-out;
            background-color: rgba(245,249,251,1.0);
            -webkit-transition: background-color 0.2s; /* Safari prior 6.1 */
            transition: background-color 0.2s;
        }
        @media ${device.mobileL} {
            margin-bottom: 16px;
            margin-right: 4px;
            margin-left: 4px;
            -webkit-box-shadow: 0px 1px 13px 2px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 1px 13px 2px rgba(0,0,0,0.1);
            box-shadow: 0px 1px 13px 2px rgba(0,0,0,0.1);    
        }
    `,
    TimeView: styled.div`
        width: 110px;
        border-right: 1px solid rgba(0,0,0,0.08);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
    `,

    InfoView: styled.div`
        flex: 1;
        min-width: 0;
        flex-direction: column;
        display: flex;  
        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 ${Spacing.medium}
        }
        .top {
            flex: 1.3;
            display: flex; 
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0,0,0,0.08);
            > img {
                height: 27px;
            }
        }
        .bottom {
            flex: 1;
            display: flex; 
            justify-content: space-between;
            flex-direction: row;
        }
    `,
    GeoIconContainer: styled.div`
        .geoIconContainer {
            margin-left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            flex: 0;
            height: 30px;    
        }
    `,
    WeekCount: styled(Typography.Header)`
        font-size: 3rem;
        color: ${Palette.Text.slate};
        height: 3rem; 
        margin-bottom: 4px;                    
    `,
    WeekLabel: styled(Typography.LargeBody)`
        color: ${Palette.Text.grey};
        margin-top: 2px;
    `,
    AddressView: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
    `,
    AddressLabel: styled(Typography.SmallBody)`
        overflow: hidden;
        text-overflow: ellipsis; 
        height: 16px; /* fix text trunction as no-wrap works poorly w/ nested flex */
        margin-left: ${Spacing.verySmall};
    `
}


const Treatment = {
    Container: styled.div`        
        display: grid;
        padding:${Spacing.medium};
        border-bottom: 1px solid rgba(0,0,0,0.08);
        grid-template-columns: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "1fr 1fr 1fr" : "1fr 1fr"};

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .cell1 {
        }
        .cell2 {
            justify-content: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "center" : "flex-end"};
            .weekCountLabel {
                margin-left: ${Spacing.verySmall};
            }
        }
        .cell3 {
            justify-content: flex-end;
        }
    `,
    IEContainer: styled.div`        
        display: grid;
        height: 50px;
        padding:${Spacing.medium};
        border-bottom: 1px solid rgba(0,0,0,0.08);
        grid-template-columns: ${(props: { isUnilabsLocation: boolean }) => props.isUnilabsLocation ? "1fr 1fr 1fr" : "1fr 1fr"};
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
        .cell1 {
        }
        .label{
            margin-left: 40px;
        }
        .cell2 {
            justify-content: "flex-end";
            margin-right: ${(window.navigator.userAgent.indexOf("Edge") > -1) ? "0" : "200px"};
            margin-left: ${(window.navigator.userAgent.indexOf("Edge") > -1) ? "200px" : "200px"};
            transform: ${(window.navigator.userAgent.indexOf("Edge") > -1) ? "translateY(-0%)" : "translateY(-140%)"};
            .weekCountLabel {
                margin-left: ${Spacing.verySmall};
            }
        }
        .cell3 {
            justify-content: flex-end;
            transform: ${(window.navigator.userAgent.indexOf("Edge") > -1) ? "translateY(-0%)" : "translateY(-140%)"};
        }
    `,
    RoundIconContainer: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        min-height: 50px;
        min-width: 50px;
        border-radius: 25px;
        background-color: ${Palette.faintBackground};
        margin-right: ${Spacing.small};
        @media ${device.mobileL} {
            display: none;           
        }
    `
}
const ModalStyle = {
    StyledModal: styled(ReactModal)`
        left:0;
        right:0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        border: 1px solid #ccc;
        background: #fff;
        height: auto;
        border-radius: 4px;
        outline: none;
        z-index: 200;
        width: 700px;
        display: flex;
        -ms-display: static;
        flex-direction: column;
        padding: 0;
        flex: 0;
        @media ${device.tablet} {
            width: 90%;           
        }
        @media ${device.mobileL} {
            height: 90%;
        }
        
    `,
    IEStyledModal: styled(ReactModal)`
        left: 0;
        right:0;
        top: 0;
        transform: translateY(50%);
        margin: auto;
        position: absolute;
        border: 1px solid #ccc;
        background: #fff;
        display: flex;
        flex: 0;
        border-radius: 4px;
        outline: none;
        z-index: 200;
        width: 700px;
        padding: 0;
    `,
    TopContaner: styled.div`
        display: table;
        overflow: auto;
        min-height: 40px;
        padding: ${Spacing.medium} ${Spacing.medium} 0 ${Spacing.medium};
        justify-content: stretch;
        @media ${device.mobileL} {
            padding-bottom: ${Spacing.small};
        }
    `,
    BottomContainer: styled.div`
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        margin-top: 0;
        margin-bottom:30px;
        height: 100%;
    `,
    TreatmentContainer: styled.div`
        @media ${device.mobileL} {
            margin-bottom: 30px;
            overflow-y: scroll;
            height: ${(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) && ((window.screen.height / window.screen.width) < 1.9) || ((window.screen.height / window.screen.width) < 2.2 && (window.screen.height / window.screen.width) > 2.165) ? "300px" : "inherit"};
            -webkit-overflow-scrolling:touch;
            padding-bottom: ${(props: { isScrollable: boolean }) => props.isScrollable ? "30px" : "0"};
        }
    `,
    GradientOverlayContainer: styled.div`
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30px;
        z-index: 1000;
        background-image: url("/Users/martinlassen/git/ventetider2/src/static/gradientOverlay.png");
        display: flex; 
        justify-content: center;
        align-self: center;
        
    `,
    reactModalStyle: {
        constent: {
            zIndex: "200",
        },
        overlay: {
            position: 'fixed',
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
            backgroundColor: 'rgba(0, 0, 0, 0.55)',
            zIndex: "100",
        },
    },

    AlignModalContainer: styled.div`
        position: fixed;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    `,
    CloseButtonContainer: styled.div`
        position: absolute;
        top: 0;
        right: 0;
        padding: ${Spacing.medium};
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `,

    Heading: styled(Typography.Header)`
        color:${Palette.primaryColor};
        @media ${device.mobileL} {
             font-size: 1.3rem;
             padding-bottom: ${Spacing.small};
             padding-right: ${Spacing.small};
        }
    `,
    StackVertical: styled.div`
        flex-direction: column;
        width: 100%;
        .seperatorContainer {
            display: flex;
            align-self: center;
            justify-content: center;
            width: 100%;
        }
    `,
    StackHorizontal: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 100%;
        width: 100%;
    `,
    AddressContainer: styled.div`
        flex-direction: row;
        display: flex;
        align-items: center;
        margin: 0;
        margin-top: ${Spacing.verySmall};
        
        height: 30px;
        margin-bottom: ${Spacing.medium};
        .secondRow {
            align-items: center;
            display: flex;
            flex-direction: row;
            max-height: 25px;
        }
        .noSpace {
            margin: 0 ${Spacing.verySmall};
        }
        @media ${device.mobileL} {
            margin-top: 0;
            margin-bottom: 0;
        }
    `,

    PinIcon: styled(IconView)`
        @media ${device.mobileL} {
                display: none;
            }
    `,
    DirectionsButton: styled.div`
        display: flex;
        flex-direction: row;
        background-color: ${Palette.faintBackground};
        align-items: center;
        justify-content: center;
        padding: 0 ${Spacing.small};
        height: 34px;
        border-radius: 15px;
        cursor: pointer; 
        @media ${device.mobileL} {
            min-height: 34px;
            min-width: 34px;
            max-height: 34px;
            max-width: 34px;
        }
    `,
    CategoryLabelContainer: styled.div`
        display: flex;
        flex-direction: row;
        background-color: ${Palette.faintBackground};
        align-items: center;
        justify-content: center;
        padding: 0 ${Spacing.small};
        height: 34px;
        border-radius: 15px;
        margin-left: ${Spacing.small};
    `,
    SmallLabel: styled(Typography.SmallBody)`
        color: ${(props: { color?: string, fontWeight?: number }) => props.color ? props.color : Palette.Text.slate};
        font-weight: ${(props: { color?: string, fontWeight?: number }) => props.fontWeight ? props.fontWeight : 400};
        margin-left: ${Spacing.verySmall};
        align-self: center;
        @media ${device.mobileL} {
            display: none;
        }
    `,

    HorizontalSeperatorLine: styled.div`
        height: 1px;
        width: 100%;
        margin: ${Spacing.small} 0;
        background-color: rgba(0,0,0,0.08);
    `,
    InfoText: styled(Typography.MediumBody)`
        
    `,
    TextContentContainer: styled.div`
        padding-top: ${Spacing.small};
        padding-bottom: ${Spacing.medium};
        min-width: 100%;
        width: 100%;
        @media ${device.mobileL} {
            padding-top: 0; 
            padding-bottom: 0;
        }
    `,
    TreatmentListHeader: styled.div`
        height: 40px;
        max-height: 40px;
        min-height: 40px;
        flex: 1;
        background-color: ${Palette.faintBackground};
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        padding-left: ${Spacing.small};
        @media ${device.mobileL} {
        
        }
    `,
    IETreatmentListHeader: styled.div`
        height: 40px;
        padding-top: 16px;
        background-color: ${Palette.faintBackground};
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
        display: table;
        width: 97.5%;
        align-items: center;
        padding-left: ${Spacing.medium};
    `,
    TreatmentListHeaderText: styled(Typography.MediumBody)`
    `
}

const translation = keyframes`
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    
  `;

const AnimatedArrowContainer = styled.div`
    animation: ${translation} 1.5s linear infinite;
    height: 30px;
    width: 30px;
    
`

function test() {

    if ((/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) && ((window.screen.height / window.screen.width) < 1.9)) {
        return 300;
    }
    if (((window.screen.height / window.screen.width) < 2.2 && (window.screen.height / window.screen.width) > 2.1)) {
        return "inherit";
    }
    else {
        return "auto";
    }
}