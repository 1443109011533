import * as React from "react";
import styled from "styled-components"
import { Typography, Palette, Spacing } from "../utils/Theme"
import { createRipples } from 'react-ripples'
import { device } from "../utils/Breakpoints"

export function Button(props: { label: string, onClick: () => void, className?: string, maxWidth?: number }) {
    return (
        <Ripples onClick={props.onClick}>
            <ButtonContainer maxWidth={props.maxWidth ? props.maxWidth : 200} className={props.className}>
                <Label>{props.label}</Label>
            </ButtonContainer>
        </Ripples>
    )
}

const Ripples = createRipples({
    color: 'rgba(0,0,0,0.05)',
    during: 700,
})

const ButtonContainer = styled.div`
    background-color: ${Palette.primaryColor};
    height: 45px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 ${Spacing.medium};
    -webkit-transition: background-color 0.2s; /* Safari prior 6.1 */
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
        transition: background-color 0.3s ease-in-out;
        background-color: #1D1A5F;
        -webkit-transition: background-color 0.2s; /* Safari prior 6.1 */
        transition: background-color 0.3s ease-in-out;
    }
    @media ${device.mobileL} {
        max-width: ${(props: { maxWidth: number }) => props.maxWidth};
        padding: 0 6px;
    }
`

const Label = styled(Typography.MediumBody)`
    text-transform: uppercase;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    @media ${device.mobileL} {
        font-size: 0.8rem;
    }
`