import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { device } from "./utils/Breakpoints";
import * as serviceWorker from './serviceWorker';
import { createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'

// Global Styles
export const GlobalStyle = createGlobalStyle`
    body {
      margin: 0;
      padding: 0;
      background-color: white;
      overflow-x: hidden;
	  /* body width is 100 of view width, minus the scrollbar width, to avoid glitches in certain brwosers when scroll is enabled. Chrome ex. */
	  width: calc(100vw - 34px);
	  @media ${device.mobileL} {
		width: 100%;
	  }
  }`

ReactDOM.render(
  <div>
    <GlobalStyle />
    <style jsx global>
      {`
              @import url("https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap" rel="stylesheet");
              @font-face {
                  font-family: "Icons";
                  src: url(${require("../src/static/fonticons.ttf")}); 
              }
          `}
    </style>
    <Router>
      <App />
    </Router>
  </div>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



