import styled from "styled-components"
import { device } from "../utils/Breakpoints"

export const ContentContainer = styled.div`
  width: 100%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
    max-width: 1300px;
  }
  @media ${device.desktop} {
    max-width: 1400px;
  }
`
