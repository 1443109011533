import * as React from "react";
import { useState, useEffect } from "react"
import styled from "styled-components"
import { NavBar } from "../components/NavBar";
import { SearchBar } from "../components/SearchBar";
import { TreatmentsList } from "../components/TreatmentsList";
import { Palette, Typography, Spacing, Icons, DefaultFontFamily } from "../utils/Theme"
import { FORM_URL } from "../api/api";
import { usePosition } from 'use-position';
import { ContentContainer } from "../components/ContentContainer"
import { device } from "../utils/Breakpoints"
import { IconView } from "../components/IconView";
import { useObserveString } from "../utils/HelperFunctions"
import { IsIE } from "./index"


export type TreatmentOptions = {
    "Bekken": number,
    "Bukhulen (abdomen)": number,
    "Ekstremiteter (armen/ben)": number,
    "Nakke/Rygg (columna)": number,
    "Hodet (caput)": number
}

export type Clinic = {
    Klinikk: string
    Adresse: string
    Avstand: number
    MR: number
    CT: number
    Annet: number
    ModalMessage: string
    ParkeringsInfo: string
    KontaktInfo: string
    ModalMR: TreatmentOptions
    ModalCT: TreatmentOptions
    ModalAnnet: TreatmentOptions
    [key: string]: any
}

export type TreatmentLocations = {
    [key: string]: Clinic[]
}

export const IsLoadingContext = React.createContext(false)

export default function MainPage(props: { isIE: boolean }) {


    const [pageIsMounted, setPageIsMounted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setError] = useState(false)
    const [items, setItems] = useState<TreatmentLocations>()
    const [inputValue, setInputValue] = useState("")
    const { latitude, longitude, timestamp, accuracy, error } = usePosition();
    const [usingGeo, setUsingGeo] = useState(false)
    const [sortByDistance, setSortByDistance] = useState(false)
    const [inputIsSelected, setInputSelected] = useState(false)
    const [cookieBarVisible, setCookieBarVisible] = useState(false)
    const welcomeText = "Ventetider.no viser ventetider for radiologiske undersøkelser (MR, CT, Røntgen og Ultralyd) der pasienten kun betaler egenandel. Trykk på instituttkortet for å se ventetider for enkeltundersøkelser.";
    const cookieText = "Unilabs nettsider lagrer informasjonskapsler eller cookies for å kunne forbedre og tilpasse nettopplevelsen din. Ved å bruke dette nettstedet samtykker du i at vi kan sette informasjon-skapsler i din nettleser."

    const [isIE, setIsIE] = useState<IsIE>(undefined)

    useEffect(() => {
        if ("ActiveXObject" in window) {
            setIsIE(true);
        } else {
            setIsIE(false);
        }
    }, [])

    useObserveString(inputValue)

    useEffect(() => {
        setPageIsMounted(true)
    }, [])

    useEffect(() => {
        if (latitude && longitude && !error) {
            setSortByDistance(true)
            setUsingGeo(true)
            setIsLoading(true)
            setInputValue("")
            setInputValue(latitude + "," + longitude)
            fetchData()
        } else if (error !== null && usingGeo === false) {
            setSortByDistance(false)
            setIsLoading(true)
            fetchData()
        }

    }, [latitude, longitude, error])

    useEffect(() => {
        if (!localStorage.getItem("cookiebarIsSeen")) {
            setCookieBarVisible(true)
        }
    }, [])

    function hideCookieBar() {
        setCookieBarVisible(false)
        localStorage.setItem("cookiebarIsSeen", "true")
    }

    async function fetchData() {
        setIsLoading(true)
        const res = await fetch(FORM_URL + (inputValue === "" ? "" : inputValue));
        (res
            .json() as Promise<any>)
            .then(res => {
                setItems(res)
                setIsLoading(false)
            })
            .catch(err => setError(err))
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        const valueLowerCase = e.target.value.toLowerCase()
        setUsingGeo(false)
        setInputValue(valueLowerCase)
    }

    function handleSubmit(e: React.MouseEvent<HTMLElement>) {
        if (inputValue !== "") {
            setSortByDistance(true)
            fetchData()
        }
    }

    function handleOnSelect() {
        setInputSelected(true)
        if (usingGeo) setInputValue("")
    }

    function handleOnDeselect() {
        setInputSelected(false)
    }

    function openTerms() {
        window.open(require("../static/terms.pdf"), "_blank")
    }

    return (
        pageIsMounted ?
            <IsLoadingContext.Provider value={isLoading}>
                <RootContainer>
                    {cookieBarVisible &&
                        <CoockieBar>
                            <StyledContentContainer isIE={isIE}>
                                <div className="innerContainer">
                                    <div className="text">
                                        <div className="consentTextParent">
                                            <SmallText>{cookieText}</SmallText>
                                        </div>
                                        <SmallButton onClick={openTerms}>
                                            <p>{"LES MER"}</p>
                                        </SmallButton>
                                    </div>
                                    <IconView onClick={hideCookieBar} color={"white"} size={20} icon={Icons.close} ></IconView>
                                </div>
                            </StyledContentContainer>
                        </CoockieBar>
                    }
                    <NavBar
                        searchBar={
                            <SearchBar
                                geoISEnabled={usingGeo}
                                onChange={handleOnChange}
                                onInputDeselected={handleOnDeselect}
                                onInputSelected={handleOnSelect}
                                onClick={handleSubmit}
                                inputValue={(usingGeo && !inputIsSelected) || (isLoading && usingGeo) ? "Søker med din posisjon" : inputValue}
                                useInlineStyle={true}
                                readOnly={isLoading && usingGeo}
                            />
                        }
                    />
                    <ContentWrapper isIE={props.isIE}>
                        <TopContainer>
                            <WelcomeLabel>{welcomeText}</WelcomeLabel>
                            <SearchBar
                                geoISEnabled={usingGeo}
                                onChange={handleOnChange}
                                onInputDeselected={handleOnDeselect}
                                onInputSelected={handleOnSelect}
                                onClick={handleSubmit}
                                inputValue={(usingGeo && !inputIsSelected) || (isLoading && usingGeo) ? "Søker med din posisjon" : inputValue}
                                id={"searchBar"}
                                readOnly={isLoading && usingGeo}
                            />
                        </TopContainer>

                        <CardGridContainer>
                            <TreatmentsList
                                sortByDistance={sortByDistance}
                                data={items}
                                hasError={hasError}
                                long={usingGeo ? longitude : undefined}
                                lat={usingGeo ? latitude : undefined}
                                isIE={props.isIE}
                            />
                        </CardGridContainer>
                    </ContentWrapper>
                </RootContainer>
            </IsLoadingContext.Provider>
            :
            null
    )
}

export const StyledContentContainer = styled(ContentContainer)`
  align-items: center;
  transform: ${(props: { isIE?: boolean }) => props.isIE ? "translateX(-50%)" : "undefined"};
`

export const RootContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    margin: 0 10px;
    @media ${device.mobileL}{
        margin:0;
    }
 `
export const CoockieBar = styled.div`
  min-height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .innerContainer {
    display: flex;
    padding: ${Spacing.small} ${Spacing.medium};
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
  .text {
    display: flex; 
    flex-wrap: wrap;
    .consentTextParent {
      padding: 6px 0;
    }
  }
`
export const SmallButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 28px;
  padding: 0 12px;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 9pt;
    color: black;
    font-weight: 600;
    font-family: ${DefaultFontFamily};
  }

`
export const SmallText = styled(Typography.SmallBody)`
  color: ${(props: { color?: string }) => props.color ? props.color : "white"};
`
export const ContentWrapper = styled.div`
   /* display: flex;  */
   flex: 1;
   width: calc(100% - ${Spacing.large});
   justify-content: center;
   flex-direction: column;
   display: ${(props: { isIE: boolean }) => props.isIE ? "table" : "flex"};
   @media ${device.tablet} {
     width: calc(100% - ${Spacing.medium});
   }
 `
export const TopContainer = styled(ContentContainer)`
   
   background-color: ${Palette.faintBackground};
   padding: ${Spacing.large} 0;  
   margin-top: 80px;
   width: 100%;  
   display: flex;
   display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
   display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
   display: -ms-flexbox;  /* TWEENER - IE 10 */
   display: -webkit-flex; /* NEW - Chrome */
   flex: 0;
   align-self: center;
   justify-content: center;
   border-radius: 40px;
   max-height: 250px;
   min-height: 150px;
   flex-direction: column;
   @media ${device.tablet} {
     padding: ${Spacing.small} 0;  
     }
 `
export const SpacingContainer = styled.div`
  height: 10vh;
`
export const WelcomeLabel = styled(Typography.Header)`
   color: ${Palette.primaryColor};
   max-width: 55%;
   margin: ${Spacing.medium} 0;
   text-align: center;
   font-size: 1.25rem;
   @media ${device.laptop} {
       font-size: 1.25rem;
       max-width: 80%;
   }
   @media ${device.mobileL} {
       font-size: 0.8rem;
       max-width: 90%;
       margin-bottom: ${Spacing.small};
   }
   @media ${device.tablet} {
       font-size: 0.8rem;
       max-width: 90%;
       margin-bottom: ${Spacing.small};
   }
   
 `
export const CardGridContainer = styled.div`
   height: 100%;
   width: 100%;
   flex: 1;
   display: flex;
 `