import * as React from "react";
import styled from "styled-components"
import { ContentContainer } from "./ContentContainer";
import { Typography, Spacing, Palette } from "../utils/Theme"
import { TreatmentView } from "./TreatmentView"
import { Clinic } from "../pages/main"
import posed from 'react-pose';
import { useContext, useState, useEffect } from "react";
import { IsLoadingContext } from "../pages/main"
import { device } from "../utils/Breakpoints";
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { CalcLatLongDistance, FormatCategoryName } from "../utils/HelperFunctions"

export enum CategoryKey {
    MR = "MR",
    CT = "CT",
    Annet = "Annet"
}

export interface IGridGroup {
    data: Clinic[] | undefined
    isIE: boolean | undefined
    userLat: number | undefined
    userLong: number | undefined
    minDistance: number
    maxDistance: number
    heading?: string
    sortByDistance?: boolean
}


export function GridGroup(props: IGridGroup) {
    const arrayOfLocations = props.data && props.data
    const [isEdge, setIsEdge] = useState<boolean>(false);


    function SortTreatments<CategoryKey extends keyof Clinic>(minDistance: number, maxDistance: number, category: CategoryKey) {
        if (typeof arrayOfLocations !== "undefined" && arrayOfLocations.length > 0 && typeof category !== "undefined") {
            // Add distance key val to object, if lat and long is defined
            arrayOfLocations.map((item, i) => {
                const locationLat = item.Lat
                const locationLong = item.Long
                if (props.userLat && props.userLong) {
                    item.distance = CalcLatLongDistance(locationLat, locationLong, props.userLat, props.userLong)
                }
            })
            // sort in ascending order, based on category waiting time
            const compareFunction = (a: Clinic, b: Clinic) => a[category] - b[category];
            const sortedByWaitTime = arrayOfLocations
                .filter(obj => typeof obj !== undefined || typeof obj !== null)
                .filter(obj => category in obj)
                .sort(compareFunction)

            //Filter within min and max distance
            const filterDistance = sortedByWaitTime.filter((item) => {
                return item.distance > minDistance && item.distance < maxDistance
            })

            if (props.sortByDistance) {
                return filterDistance
            } else {
                return sortedByWaitTime;
            }
        }
    }

    const MRArray = SortTreatments(props.minDistance, props.maxDistance, CategoryKey.MR)
    const CTArray = SortTreatments(props.minDistance, props.maxDistance, CategoryKey.CT)
    const OtherTreatmentsArray = SortTreatments(props.minDistance, props.maxDistance, CategoryKey.Annet)

    const gridIsNotEmpty =
        typeof MRArray !== "undefined" && MRArray.length > 0
        || typeof CTArray !== "undefined" && CTArray.length > 0
        || typeof OtherTreatmentsArray !== "undefined" && OtherTreatmentsArray.length > 0

    const BrowserSpesificGrid = props.isIE ? IEGridLayout : GridLayout;
    const BrowserSpesificStackContainer = isEdge ? EdgeStackContainer : StackContainer;


    return (
        gridIsNotEmpty ?
            <div>
                <BrowserSpesificStackContainer>
                    {props.heading !== "" &&
                        <FeedSeperationContainer>
                            <LargeHeader>{props.heading}</LargeHeader>
                        </FeedSeperationContainer>
                    }

                    <GridContainer isIE={props.isIE ? props.isIE : false}>
                        <BrowserSpesificGrid>
                            <div className="column1">
                                <StackedCardsColumn category={CategoryKey.MR} treatmentItems={MRArray} />
                            </div>
                            <div className="column2">
                                <StackedCardsColumn category={CategoryKey.CT} treatmentItems={CTArray} />
                            </div>
                            <div className="column3">
                                <StackedCardsColumn category={CategoryKey.Annet} treatmentItems={OtherTreatmentsArray} />
                            </div>
                        </BrowserSpesificGrid>
                    </GridContainer>
                </BrowserSpesificStackContainer>
            </div>
            :
            null
    )
}


type ScrollPosition = {
    prevPos: { [key: string]: number }
    currPos: { [key: string]: number }
}

export const StackedCardsColumn = React.memo((props: { treatmentItems?: Clinic[], category: CategoryKey, isIE?: boolean }) => {
    const isLoading = useContext(IsLoadingContext);
    const [stickyHeader, setStickyHeader] = useState(false);
    const [navBarHeight, setNavBarHeight] = useState<any>(0);

    const headerPos = document.getElementById("headerContainer") ? document.getElementById("headerContainer")!.offsetTop : null
    const navBar = document.getElementById("navBar")
    const itemCount = props.treatmentItems && props.treatmentItems.length

    // Not setting navBarHeight if IE, therefore not using stickyHeaders
    useEffect(() => {
        if ("ActiveXObject" in window) {
        } else {
            setNavBarHeight(navBar!!.offsetHeight)
        }
    })

    useScrollPosition(({ prevPos, currPos }: ScrollPosition) => {
        const sticky = headerPos !== null ? currPos.y < -(headerPos! - navBarHeight) : null
        if (sticky !== stickyHeader) setStickyHeader(sticky!)
    }, [stickyHeader])


    function filterTreatmentOptions<TData, ModalKey extends keyof TData>(data: TData, key: ModalKey) {
        return data[key]
    }

    return (
        itemCount && itemCount > 0 ?
            <div>
                <StickyHeaderContainer id="headerContainer" isSticky={stickyHeader} navBarHeight={navBarHeight}>
                    <ResultHeader isSticky={stickyHeader}>{FormatCategoryName(props.category)}</ResultHeader>
                </StickyHeaderContainer>
                <AnimatedStackContainer initialPose={"close"} pose={!isLoading ? "open" : "close"}>
                    {props.treatmentItems && props.treatmentItems.map((item, i) => {
                        return (
                            item[props.category] ?
                                <AnimatedTreatmentView key={i}>
                                    <TreatmentView
                                        weekcount={item[props.category]}
                                        name={item.Klinikk} address={item.Adresse}
                                        treatmentOptions={filterTreatmentOptions(item, "Modal" + props.category)}
                                        parkingInfo={item.ParkeringsInfo}
                                        locationInfo={item.ModalMessage}
                                        contactInfo={item.KontaktInfo}
                                        treatmentCategory={Object.keys(item).find(key => props.category === key)}
                                        lat={item.Lat}
                                        long={item.Long}
                                    />
                                </AnimatedTreatmentView>
                                :
                                null
                        )
                    })}
                </AnimatedStackContainer>
            </div>
            :
            null
    )
})


type StickyHeaderStyleProps = {
    isSticky: boolean
    navBarHeight?: number
}

const StickyHeaderContainer = styled.div`
        background-color: ${(props: StickyHeaderStyleProps) => props.isSticky ? "rgba(255,255,255,0.85)" : "rgba(255,255,255,0)"};
        position: ${(props: StickyHeaderStyleProps) => props.isSticky ? "sticky" : "static"};
        top: ${(props: StickyHeaderStyleProps) => props.isSticky ? props.navBarHeight + "px" : undefined};
        left: ${(props: StickyHeaderStyleProps) => props.isSticky ? "0" : undefined};
        width: 100%;
        transition: all 50ms ${(props: StickyHeaderStyleProps) => props.isSticky ? "ease-out" : "ease-in"};
        border-bottom: 1px solid ${(props: StickyHeaderStyleProps) => props.isSticky ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0)"};
        `
const ResultHeader = styled(Typography.Header)`
        text-align: left;
        color: ${Palette.primaryColor};
        margin-bottom: ${Spacing.small};
        margin-top: ${Spacing.large};
        z-index: 50;
        top: ${(props: StickyHeaderStyleProps) => props.isSticky ? "5" : "0"};
        @media ${device.tablet} {
            margin-top: ${Spacing.medium}     
        }       
        `


const categoryAnimationProps = {
    open: {
        y: 0,
        opacity: 1,

        staggerChildren: 30,
        transition: {
            ease: 'easeOut',
            duration: 201
        }
    },
    close: {
        y: 50,
        opacity: 0,

        staggerChildren: 30,
        afterChildren: true,
        transition: {
            ease: 'easeOut',
            duration: 200,
        }
    }

}

const AnimatedTreatmentView = styled(posed.div(categoryAnimationProps))``

const StackContainer = styled.div`
        width: 100%;

        > div {
            margin: 10px 0 10px 0;
        }
        `
const EdgeStackContainer = styled.div`
        width: 100%;
        > div {
            margin: 10px 0 10px 0;
        }
`

const AnimatedStackContainer = styled(posed.div(categoryAnimationProps))`
        width: 100%;
        > div {
            margin: 10px 0 10px 0;
        }
        `

const FeedSeperationContainer = styled.div`
        display: flex;
        align-items: flex-end;
        padding-top: ${Spacing.large};
        border-bottom: 1px solid rgba(0,0,0,0.2);
        padding-bottom: ${Spacing.small};
        `

const LargeHeader = styled(Typography.LargeBody)`
        color: ${Palette.Text.grey};
        `

const GridContainer = styled(ContentContainer)`
        width: 100%;
        width: ${(props: { isIE: boolean }) => props.isIE ? "100%" : "100%"};
        display: ${(props: { isIE: boolean }) => props.isIE ? "table" : "flex"};
        align-self:center;
        justify-content: center;
        `

const IEGridLayout = styled.div`      
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        -ms-grid-rows: 100%;
        
        .column1 {
            -ms-grid-column: 1;
            grid-column: 1;
            margin: 0 5px;
        } 
        .column2 {
            -ms-grid-column: 2;
            grid-column: 2;
            margin: 0 5px;
        } 
        .column3 {
            -ms-grid-column: 3;
            grid-column: 3;
            margin: 0 5px;
        } 
        `

const GridLayout = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 10px;
        width: 100%;
        height: 100%;
        align-self: center;
        @media ${device.laptop} {
            grid-template-columns: 1fr 1fr;           
        }
        @media ${device.tablet} {
            grid-template-columns: 1fr;        
        }
        `


