import * as React from "react";
import styled from "styled-components"
import { Spacing, Icons, Palette, DefaultFontFamily } from "../utils/Theme";
import { IconView } from "./IconView";
import { device } from "../utils/Breakpoints"

interface SearchBar {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onClick: (e: React.MouseEvent<HTMLElement>) => void
    getGeoLocation?: () => void
    inputValue: string
    id?: string
    useInlineStyle?: boolean
    geoISEnabled?: boolean
    onInputSelected?: any
    onInputDeselected?: any
    readOnly?: boolean
}

type StyleProps = {
    isActive?: boolean
    inlineStyles?: boolean
    readOnlyStyle?: boolean
}

export const SearchBar = React.memo((props: SearchBar) => {
    // Searchfield can be instantiated with two different styles. `useInlineStyles` applies styles that makes it fit the NavBar, by making it smaller and more compact
    const useInlineStyle = props.useInlineStyle ? props.useInlineStyle : false
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
    const activateSearch = props.inputValue.length > 3 && !props.geoISEnabled

    function handleKeyPress(e: any) {
        if (e.key === 'Enter') {
            props.onClick(e)
        }
    }

    // Conditional styles
    const Container = useInlineStyle ? InlineSearchBarContainer : SearchBarContainer
    return (
        <Container id={props.id}>
            <TextInput readOnlyStyle={props.readOnly} readOnly={props.readOnly} placeholder={isMobileDevice ? "Skriv adresse" : "Skriv sted eller adresse"} onBlur={props.onInputDeselected} onFocus={props.onInputSelected} onChange={props.onChange} onKeyDown={handleKeyPress} value={props.inputValue} />
            <SearchButton onClick={activateSearch ? props.onClick : undefined} isActive={activateSearch} inlineStyles={props.useInlineStyle} >
                <IconView icon={Icons.search} color={"white"} size={props.useInlineStyle ? 10 : 15} ></IconView>
            </SearchButton>
        </Container>
    )
})

const InlineSearchBarContainer = styled.div`
    background-color: ${Palette.faintBackground};
    border-radius: 20px;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    input:-webkit-autofill, input:-webkit-autofill:focus {
        box-shadow: 0;
        -webkit-box-shadow: 0 0 0 0 white inset;
        -webkit-text-fill-color: white;
    }
`
const SearchBarContainer = styled.div`
    margin: ${Spacing.medium} 0;
    height:  70px;
    min-height: 70px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${Spacing.small};
    background-color: rgba(255,255,255,1);
    -webkit-box-shadow: 0px 6px 8px -2px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 6px 8px -2px rgba(0,0,0,0.05);
    box-shadow: 0px 6px 8px -2px rgba(0,0,0,0.05);
    transition: box-shadow 0.3s ease-in-out;
    -webkit-transition: box-shadow 0.3s ease-in-out;
    border-radius: 35px;
    &:focus-within {
        box-shadow: 0px 10px 8px -2px rgba(0,0,0,0.1);
        -webkit-box-shadow: 0px 10px 8px -2px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 10px 8px -2px rgba(0,0,0,0.1);
        transition: box-shadow 0.3s ease-in-out;
        -webkit-transition: box-shadow 0.3s ease-in-out;
    }
    
    @media ${device.mobileL} {
        min-height: 60px;
        height: 60px;
        width: 85%;
        margin-top: ${Spacing.small};   
    }
  `

const TextInput = styled.input`
    height: 100%;
    width: 100%;
    outline:none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    box-shadow: none !important;
    background-color: rgba(0,0,0,0);
    -webkit-autofill { background: rgba(0,0,0,0) !important; }
    display: flex; 
    align-self: center;
    justify-content: center;
    padding-left: ${Spacing.medium};
    font-size: ${(props: StyleProps) => props.inlineStyles ? "0.9rem" : "1.2rem"};
    font-family: ${DefaultFontFamily};
    color: ${(props: StyleProps) => props.readOnlyStyle ? Palette.Text.grey : Palette.Text.slate};
    margin: 0;
    ::placeholder {
        color: ${Palette.Text.grey};
        opacity: 0.7;
    }
    @media ${device.tablet} {
        font-size: ${(props: StyleProps) => props.inlineStyles ? "0.8rem" : "1.0rem"};
    }
  `

const SearchButton = styled.div`
    background-color: ${(props: StyleProps) => props.isActive ? Palette.primaryColor : "rgba(173,183,203,1)"};
    min-height: ${(props: StyleProps) => props.inlineStyles ? "25px" : "50px"};
    max-height: ${(props: StyleProps) => props.inlineStyles ? "25px" : "50px"};
    min-width: ${(props: StyleProps) => props.inlineStyles ? "25px" : "50px"};
    max-width: ${(props: StyleProps) => props.inlineStyles ? "25px" : "50px"};
    border-radius: ${(props: StyleProps) => props.inlineStyles ? "12.5px" : "25px"};
    border-right: 1px solid rgba(0,0,0,0.08);
    border-top: 1px solid rgba(0,0,0,0.08);
    border-bottom: 1px solid rgba(0,0,0,0.08);
    display: flex;
    align-self: center;
    justify-content: center;
    vertical-align: middle;
    cursor: ${(props: StyleProps) => props.isActive ? "pointer" : "auto"};
  `
