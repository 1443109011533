import React from 'react';
import './App.css';

import { createGlobalStyle } from 'styled-components'
import { Palette } from "./utils/Theme";
import { device } from "./utils/Breakpoints";
import { fonticons } from '../src/static/fonticons.ttf';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import AboutPage from './pages/about';
import Home from './pages/index';

function App() {
  return (
    <main>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={AboutPage} />
      </Switch>
    </main>
  );
}

// Global Styles
export const GlobalStyle = createGlobalStyle`
    body {
      margin: 0;
      padding: 0;
      background-color: white;
      overflow-x: hidden;
	  /* body width is 100 of view width, minus the scrollbar width, to avoid glitches in certain brwosers when scroll is enabled. Chrome ex. */
	  width: calc(100vw - 34px);
	  @media ${device.mobileL} {
		width: 100%;
	  }
  }`

export default App;
